"use strict";
router();
$(window).on("load", function () {
    $("a.header-menu__link[data-href=" + $(".page-wrapper").data("href") + "]").addClass("active");
    sphereSize();
    loadAnimation();
    allScriptsLoad($(".page-wrapper"));
    setTimeout(() => {
        $("html").addClass("done");
    }, 1000);
    if (!$(".page").hasClass("body--index")) {
        arrowAnimation();
        elementsAnimation($(".page-wrapper"));
        indexSlider($(".page-wrapper"));
    }
});

function allScriptsLoad(container) {

    // GLOBAL VARS
    window.miniatureSliderInit = false;
    window.miniatureSliderObj;
    backLink();
    sliders(container);
    vortex(container.find('#vortexCanvas')[0], 180, 1, 3.5);
    vortex(container.find('#conversionCanvas')[0], 200, .7, 2);
    vortexResult(container.find('#conversionCanvasResult')[0], 40, 4, 1.2);
    videoLoad();
    select(container);
    accordion();
    fixedElements();
    countryDrop();
    documentClick();
    popups();
    scrollTop();
    miniature();
    headerMenu();
    disableHover();

    $(window).on("scroll", function () {
        fixedElements();
    });
    $(window).on("resize", function () {
        miniatureSize();
        miniatureSlider();
        fixedElements();
    }).trigger("resize");
}

function sphereSize() {
    $(window).on("resize", function () {
        var size;
        if ($(window).innerWidth() > 1200) {
            size = $(window).innerHeight() / 2;
        }
        else if ($(window).innerWidth() > 991 && $(window).innerWidth() < 1201) {
            size = $(window).innerHeight() / 2.3;
        }
        else if ($(window).innerWidth() > 650 && $(window).innerWidth() < 992) {
            size = $(window).innerHeight() / 2.8;
        }
        else if ($(window).innerWidth() > 0 && $(window).innerWidth() < 651) {
            size = $(window).innerHeight() / 3.2;
        }
        var loaderAnimaValue = 2 * Math.PI * (size / 2 - 10);
        $(".loader-indicator circle").css({ "stroke-dasharray": loaderAnimaValue, "stroke-dashoffset": loaderAnimaValue, "r": size / 2 - 10 });
        $(".loader-main").css({ "width": size + "px", "height": size + "px" });
    }).trigger("resize");
}

function disableHover() {
    function hasTouch() {
        return 'ontouchstart' in document.documentElement
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0;
    }
    if (hasTouch()) {
        $("body").addClass("hasTouch");
    }
}

function vortexResult(canvas, particles, size, width) {
    // standard shim
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();

    // helper functions
    function randomMax(max) {
        return Math.floor(Math.random() * max);
    }
    function getParticleColor() {
        var r = (100 + randomMax(155));
        var g = (100 + randomMax(155));
        var b = (100 + randomMax(155));

        return 'rgb(' + r + ',' + g + ',' + b + ')';
    }
    function refreshColor() {
        for (var i = 0; i < particleSystem.particles.length; i++) {
            particleSystem.particles[i].color = singlecolor ? defaultColor : getParticleColor();
        }
    }

    var ctx = canvas.getContext('2d');
    var fps = 0, now, lastUpdate = (new Date()) * 1 - 1, fpsFilter = 50;

    // globals
    var numParticles = particles,
        angleSpeed = 0.03,
        particleSize = size,
        verticalSpeed = 2,
        widthFactor = width,
        singlecolor = true,
        defaultColor = '#fff';

    var Particle = function () {
        this.h = Math.floor(canvas.height * Math.random());
        this.angle = Math.random() * Math.PI * 2;
        this.color = singlecolor ? defaultColor : getParticleColor();
    };

    Particle.prototype.draw = function (id) {
        this.angle += angleSpeed;
        this.h -= verticalSpeed;

        if (this.h < 0 || this.h > canvas.height) {
            this.h = Math.floor(canvas.height * Math.random());
        }

        ctx.beginPath();
        ctx.fillStyle = singlecolor ? defaultColor : this.color;
        var sizeFactor = 0.5 + (Math.sin(this.angle) + 1) / 2;
        ctx.arc(canvas.width / 2 + Math.cos(this.angle) * (canvas.height - this.h) / widthFactor, this.h, particleSize * sizeFactor, 0, Math.PI * 2);
        ctx.fill();
    };

    var ParticleSystem = function () {
        this.particles = [];
        for (var i = 0; i < numParticles; i++) {
            this.particles.push(new Particle(canvas.height * Math.random()));
        }
    };
    ParticleSystem.prototype.draw = function () {
        for (var i = 0; i < this.particles.length; i++) {
            this.particles[i].draw();
        }
    };

    var particleSystem = new ParticleSystem();

    window.onresize = function () {
        particleSystem = new ParticleSystem();
    };

    (function animloop() {
        requestAnimFrame(animloop);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        particleSystem.draw();
        var thisFrameFPS = 1000 / ((now = new Date()) - lastUpdate);
        fps += (thisFrameFPS - fps) / fpsFilter;
        lastUpdate = now;
    })();
}

function vortex(canvas, particles, size, width) {

    // standard shim
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();

    // helper functions
    function randomMax(max) {
        return Math.floor(Math.random() * max);
    }
    function getParticleColor() {
        var r = (100 + randomMax(155));
        var g = (100 + randomMax(155));
        var b = (100 + randomMax(155));

        return 'rgb(' + r + ',' + g + ',' + b + ')';
    }
    function refreshColor() {
        for (var i = 0; i < particleSystem.particles.length; i++) {
            particleSystem.particles[i].color = singlecolor ? defaultColor : getParticleColor();
        }
    }

    var ctx = canvas.getContext('2d');
    var fps = 0, now, lastUpdate = (new Date()) * 1 - 1, fpsFilter = 50;

    // globals
    var numParticles = particles,
        angleSpeed = 0.03,
        particleSize = size,
        verticalSpeed = -2,
        widthFactor = width,
        singlecolor = true,
        defaultColor = '#fff';

    var Particle = function () {
        this.h = Math.floor(canvas.height * Math.random());
        this.angle = Math.random() * Math.PI * 2;
        this.color = singlecolor ? defaultColor : getParticleColor();
    };

    Particle.prototype.draw = function (id) {
        this.angle += angleSpeed;
        this.h -= verticalSpeed;

        if (this.h < 0 || this.h > canvas.height) {
            this.h = Math.floor(canvas.height * Math.random());
        }

        ctx.beginPath();
        ctx.fillStyle = singlecolor ? defaultColor : this.color;
        var sizeFactor = 0.5 + (Math.sin(this.angle) + 1) / 2;
        ctx.arc(canvas.width / 2 + Math.cos(this.angle) * (canvas.height - this.h) / widthFactor, this.h, particleSize * sizeFactor, 0, Math.PI * 2);
        ctx.fill();
    };

    var ParticleSystem = function () {
        this.particles = [];
        for (var i = 0; i < numParticles; i++) {
            this.particles.push(new Particle(canvas.height * Math.random()));
        }
    };
    ParticleSystem.prototype.draw = function () {
        for (var i = 0; i < this.particles.length; i++) {
            this.particles[i].draw();
        }
    };

    var particleSystem = new ParticleSystem();

    window.onresize = function () {
        particleSystem = new ParticleSystem();
    };

    (function animloop() {
        requestAnimFrame(animloop);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        particleSystem.draw();
        var thisFrameFPS = 1000 / ((now = new Date()) - lastUpdate);
        fps += (thisFrameFPS - fps) / fpsFilter;
        lastUpdate = now;
    })();
}

function router() {
    document.addEventListener("DOMContentLoaded", function () {
        var lastElementClicked;

        Barba.Pjax.init();
        Barba.Prefetch.init();

        Barba.Dispatcher.on('linkClicked', function (el) {
            lastElementClicked = el;
        });

        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        Barba.Pjax.preventCheck = function (evt, el) {
            if (!Barba.Pjax.originalPreventCheck(evt, el)) {
                return false;
            }
            else if ($(el).attr('target') == "_blank") {
                return false;
            }

            return true;
        };

        var MovePage = Barba.BaseTransition.extend({
            start: function () {
                this.originalThumb = lastElementClicked;

                Promise
                    .all([this.newContainerLoading, this.scrollTop()])
                    .then(this.movePages.bind(this));
            },

            scrollTop: function () {
                var deferred = Barba.Utils.deferred();
                deferred.resolve();
            },

            movePages: function () {
                var _this = this;

                $("html").addClass("animated");

                TweenLite.set(_this.newContainer, {
                    display: 'flex',
                    visibility: 'visible',
                    position: 'fixed',
                    zIndex: 100,
                    transform: 'translate3d(0, 0, 0)',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                });

                setTimeout(() => {
                    // Remove active menu link
                    $(_this.newContainer).find("a.header-menu__link").removeClass("active");
                    // Add active menu link
                    $(_this.newContainer).find("a.header-menu__link[data-href=" + $(_this.newContainer).data("href") + "]").addClass("active");
                    $(_this.newContainer).find(".page").addClass("anime");

                    indexSlider($(_this.newContainer));
                    $(".loader").addClass("locked");
                    $(_this.newContainer).find(".sidebar").removeClass("absolute");
                    allScriptsLoad($(_this.newContainer));
                    elementsAnimation($(_this.newContainer));
                    arrowAnimation();
                    TweenLite.to(_this.newContainer, 1.2, {
                        onComplete: function () {
                            _this.done();
                            window.scrollTo(0, 0);
                            $("html").attr("class", "");
                            $("html").addClass($(_this.newContainer).data("class") + " done");
                            $(".page").addClass("done").removeClass("anime");
                            TweenLite.set(_this.newContainer, { clearProps: 'all' });
                        }
                    });
                }, 300);
            },

            getNewPageFile: function () {
                return Barba.HistoryManager.currentStatus().url.split('/').pop();
            }
        });

        Barba.Pjax.getTransition = function () {
            return MovePage;
        };
    });
}

function loadAnimation() {
    loader();

    $(".page").addClass("anime");
    setTimeout(() => {
        $(".page").addClass("done");
    }, 1300);
}

function isOnScreen(elem) {
    var elementTop = elem.offset().top;
    var elementBottom = elementTop + elem.innerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).innerHeight();
    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function elementsAnimation(container) {
    $(window).on("scroll", function () {
        container.find(".fade").each((index, item) => {
            if (isOnScreen($(item))) {
                $(item).addClass("active");
            }
        });
    });
    container.find(".fade").each((i, item) => {
        if (!isOnScreen($(item))) {
            $(item).css({ "transition-delay": ".1s", "transition-duration": ".9s" });
        }
        else {
            $(item).css({ "transition-delay": $(item).data("delay"), "transition-duration": $(item).data("duration") });
            $(item).addClass("active");
        }
    });
    container.find(".product-categories__item").each((i, item) => {
        $(item).addClass("active");
    });
    container.find(".image-anime").each((i, item) => {
        $(item).addClass("active");
    });
    container.find(".miniature-item").each((i, item) => {
        $(item).addClass("active");
        setTimeout(() => {
            $(item).find(".miniature-item__border").attr("style", "");
        }, 1300);
    });
    container.find(".responsibility-header__scheme").addClass("active");
    container.find(".miniature-popup").each((i) => {
        var item = $(this);
        item.addClass("anime");
    });
}

function arrowAnimation() {
    $(window).on("scroll", function () {
        $(".arrow-anime").each((index, item) => {
            if (isOnScreen($(item))) {
                $(item).addClass("active");
            }
        });
    });
    $(".arrow-anime").each((index, item) => {

        // $(item).find("circle").css({ "transition-delay": $(item).data("delay"), "transition-duration": $(item).data("duration") });
        // $(item).find("svg").css({ "transition-delay": $(item).data("delay"), "transition-duration": $(item).data("duration") });
        if (!isOnScreen($(item))) {
            $(item).find("circle").css({ "transition-delay": ".1s", "transition-duration": ".9s" });
            $(item).find("svg").css({ "transition-delay": ".1s", "transition-duration": ".9s" });
        }
        else {
            $(item).find("circle").css({ "transition-delay": $(item).data("delay"), "transition-duration": ".8s" });
            $(item).find("svg").css({ "transition-delay": $(item).data("delay"), "transition-duration": ".8s" });
            $(item).addClass("active");
        }
    });


}

function loader() {
    window.finishLoaderState = false;
    window.beforefinishLoaderState = false;
    function finishLoader() {
        if (!window.finishLoaderState) {
            window.finishLoaderState = true;
            indexSlider($(".page-wrapper"));
            $(".main").addClass("active");
            arrowAnimation();
            elementsAnimation($(".page-wrapper"));
        }
    }
    if ($(".page").hasClass("body--index")) {
        $(".page-main").addClass("page-main--blue");
        $(".loader").addClass("active");
        setTimeout(() => {
            $(".loader-indicator circle").css({ "stroke-dashoffset": 0, transition: "stroke-dashoffset 1.5s ease" });
            setTimeout(() => {
                $(".loader-indicator svg").addClass("active");
                $(".loader-indicator circle").css({ "stroke-dashoffset": $(".loader-indicator circle").css("stroke-dasharray") });
            }, 1500);
            $(".loader-indicator__circles").addClass("active");
            var count = 0;
            var innterval = setInterval(() => {
                count++;
                if (count == 100) {
                    clearInterval(innterval);
                }
                else if (count < 10) {
                    $(".loader-main__percent-value").html("0" + count);
                }
                else {
                    $(".loader-main__percent-value").html(count);
                }
            }, 12);
        }, 800);
        setTimeout(() => {
            $(".loader").addClass("hidden");
            $(".index-slide").removeClass("active");
            $(".index-slider__block").removeClass("active");
        }, 1700);
        setTimeout(() => {
            window.beforefinishLoaderState = true;
        }, 5000);
        $('.loader').bind('mousewheel', function (e) {
            if (e.originalEvent.wheelDelta < 0) {
                if (window.beforefinishLoaderState) {
                    finishLoader();
                }
            }
        });
        var el = $(".loader")[0];
        if (el) {
            swipedetect(el, function (swipedir) {
                if (swipedir == 'up') {
                    if (window.beforefinishLoaderState) {
                        finishLoader();
                    }
                }
            });
        }
        $(".loader-scroll").click(function () {
            if (window.beforefinishLoaderState) {
                finishLoader();
            }
        });
    }
}

function backLink() {
    $('.js-back-link').on('click', function (e) {
        e.preventDefault();
        window.history.back();
    });
}

function miniatureSlider() {
    if ($(".js-miniature-slider").length > 0) {
        if ($(window).innerWidth() <= 650) {
            if (!window.miniatureSliderInit) {
                window.miniatureSliderInit = true;
                window.miniatureSliderObj = new Swiper(".js-miniature-slider", {
                    loop: true,
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    centeredSlides: true
                });
            }
        }
        else {
            if (window.miniatureSliderInit) {
                window.miniatureSliderInit = false;
                window.miniatureSliderObj.destroy();
            }
        }
    }

}

function miniatureSize() {
    // TODO: REMOVE AFTER ANIMATION
    // setTimeout(() => {
    //     $(".miniature-popups").each((index, item) => {
    //         $(item).css("width", $(item).prev(".miniature-detail__bg").find("img").innerWidth());
    //     });
    // }, 200);
}

function headerMenu() {
    $(".js-header-burger").on("click", function (e) {
        e.preventDefault();
        $(this).stop().toggleClass("active");
        $(".header-drop").stop().toggleClass("active");
        $(".header-menu--tablet").stop().toggleClass("active");
    });
    // var _overlay = document.querySelector('.header-drop');
    // var _clientY = null; // remember Y position on touch start
    // _overlay.addEventListener('touchstart', function (event) {
    //     if (event.targetTouches.length === 1) {
    //         _clientY = event.targetTouches[0].clientY;
    //     }
    // }, false);
    // _overlay.addEventListener('touchmove', function (event) {
    //     if (event.targetTouches.length === 1) {
    //         disableRubberBand(event);
    //     }
    // }, false);
    // function disableRubberBand(event) {
    //     var clientY = event.targetTouches[0].clientY - _clientY;
    //     if (_overlay.scrollTop === 0 && clientY > 0) {
    //         event.preventDefault();
    //     }
    //     if (isOverlayTotallyScrolled() && clientY < 0) {
    //         event.preventDefault();
    //     }
    // }
    // function isOverlayTotallyScrolled() {
    //     return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
    // }
}

function scrollTop() {
    $(".js-scroll-top").on("click", function () {
        $("html, body").animate({
            scrollTop: 0
        }, 800);
    });
}

function miniature() {
    $(".js-miniature-popup-open").on("click", function () {
        $(".miniature-popup").removeClass("active").addClass("hidden");
        $(this).closest(".miniature-popup").addClass("active").removeClass("hidden");
    });
    $(".js-miniature-popup-close").on("click", function () {
        $(".miniature-popup").removeClass("hidden");
        $(this).closest(".miniature-popup").removeClass("active");
    });
}

function popups() {
    $(".js-popup-open").on("click", function (e) {
        e.preventDefault();
        var popup = $(this).attr("href");
        if ($(popup).length != 0) {
            $(popup).addClass("active");
            $("html").addClass("static");
        }
    });

    $(".js-popup-close").on("click", function () {
        $(".popup").removeClass("active");
        $("html").removeClass("static");
    });

    $(".js-conversion-open").on("click", function (e) {
        e.preventDefault();
        $(".conversion").addClass("active");
    });

    $(".js-conversion-close").on("click", function () {
        $(".conversion").removeClass("active");
    });
}

function documentClick() {
    $(document).on("click", function (e) {
        if ($(".header-country").has(e.target).length === 0) {
            $(".js-country-toggle").removeClass("active");
            $(".js-country-drop").removeClass("active");
        }
        if ($(".conversion").has(e.target).length === 0 && $(".js-conversion-open").has(e.target).length === 0) {
            $(".conversion").removeClass("active");
        }
        if ($(".miniature-popup").has(e.target).length === 0) {
            $(".miniature-popup").removeClass("hidden active");
        }
    });
}

function countryDrop() {
    // SET COUNTRY CODE AND NAME TO COUNTRY SELECT FROM HTML TAG
    $(".js-country-value").attr("data-country", $("html").attr("data-country-code"));
    $(".js-country-value").text($("html").attr("data-country-name"));

    $(".js-country-drop").find(".header-country__item[data-country=" + $(".js-country-value").data("country") + "]").addClass("active");
    $(".js-country-toggle").on("click", function () {
        $(this).stop().toggleClass("active");
        $(this).next(".js-country-drop").toggleClass("active");
    });
    $(".js-country-item").on("click", function () {
        $(".js-country-item").removeClass("active");
        $(this).addClass("active");
        $(".js-country-value").text($(this).text());
        $(".js-country-value").attr("data-country", $(this).data("country"));
        $("html").attr("data-country-name", $(this).text());
        $("html").attr("data-country-code", $(this).data("country"));
        $(".js-country-toggle").trigger("click");
    });
}

function videoLoad() {
    $(".js-video-btn").on("click", function () {
        var frame = `
        <iframe 
            src="`+ $(this).attr('data-link') + `?autoplay=1" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
        `;
        $(this).closest(".js-video-content").append(frame);
    });
}

function fixedElements() {
    if ($(".contacts-wrap").length > 0) {
        $(".contacts-controls").css("width", $(".contacts-controls__wrap").innerWidth() + "px");
        if ($(window).scrollTop() > $(".contacts-wrap").offset().top - $(".header").innerHeight()) {
            $(".contacts-controls").addClass("fixed");
        }
        else {
            $(".contacts-controls").removeClass("fixed");
        }
    }
    if ($(".category-media__block").length > 0) {
        $(".category-content").css("padding-top", $(".category-media__header").innerHeight() + "px");
        $(".category-media__block").css("width", $(".category-media").innerWidth() + "px");
        if ($(window).scrollTop() + $(".category-media__block").position().top + $(".category-media__block").innerHeight() >= $(".category-media").offset().top + $(".category-media").innerHeight()) {
            $(".category-media__block").addClass("absolute");
        }
        if ($(window).scrollTop() + $(".header").innerHeight() + parseInt($(".page-block").css("padding-top")) < $(".category-media__block").offset().top) {
            $(".category-media__block").removeClass("absolute");
        }
    }
    if ($(".sidebar").length > 0) {
        if ($(window).scrollTop() + $(".header").innerHeight() + $(".sidebar-inner").innerHeight() >= $(".wrap").offset().top + $(".wrap").innerHeight()) {
            $(".sidebar").addClass("absolute");
        }
        else {
            $(".sidebar").removeClass("absolute");
        }
    }
}

function accordion() {
    $(".js-accordion-btn").on("click", function () {
        if (!$(this).hasClass("active")) {
            $("html, body").animate({
                scrollTop: $(this).closest(".accordion-item").offset().top - $(".header").innerHeight() + 2
            }, 500)
        }
        $(this).stop().toggleClass("active");
        $(this).stop().next(".js-accordion-content").slideToggle(500);
    });
}

function select(container) {
    container.find('.select').each((i, item) => {
        $(item).select2({
            minimumResultsForSearch: -1,
            width: '100%',
            closeOnSelect: false,
            dropdownParent: $(item).next(".select-drop")
        }).on("select2:closing", function (e) {
            e.preventDefault();
        }).on("select2:closed", function (e) {
            $(item).select2("open");
        }).on("select2:select", function (e) {
            $(item).closest(".select-container").stop().toggleClass("active");
            $(item).closest(".select-container").find(".select-drop").stop().slideToggle("250");
        });
        $(item).select2("open");
        $(item).next(".select2").on("click", function (e) {
            if (!$(item).closest(".select-container").hasClass("active")) {
                $(".select-container").stop().removeClass("active");
                $(".select-drop").stop().slideUp("250", "swing");
            }
            $(item).closest(".select-container").stop().toggleClass("active");
            $(item).closest(".select-container").find(".select-drop").stop().slideToggle("250", "swing");
        });
    });

    setTimeout(() => {
        $(".select2").addClass("active");
    }, 100);

    container.find(".js-sidebar-select").on('select2:select', function (e) {
        var data = e.params.data;
        Barba.Pjax.goTo(data.element.value);
    });
}

function swipedetect(el, callback) {

    var touchsurface = el,
        swipedir,
        startX,
        startY,
        distX,
        distY,
        threshold = 80, //required min distance traveled to be considered swipe
        restraint = 50, // maximum distance allowed at the same time in perpendicular direction
        allowedTime = 500, // maximum time allowed to travel that distance
        elapsedTime,
        startTime,
        handleswipe = callback || function (swipedir) { }

    touchsurface.addEventListener('touchstart', function (e) {
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        distX = 0
        distY = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
    }, false)

    touchsurface.addEventListener('touchmove', function (e) {
        e.preventDefault() // prevent scrolling when inside DIV
    }, false)

    touchsurface.addEventListener('touchend', function (e) {
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime) { // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // 2nd condition for horizontal swipe met
                swipedir = (distX < 0) ? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // 2nd condition for vertical swipe met
                swipedir = (distY < 0) ? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir)
    }, false)
}

function indexSlider(container) {
    var slideLength = container.find(".index-slide").length;
    container.find(".index-slider__block").addClass("active");
    container.find(".index-slide").removeClass("active");
    container.find(".index-slide").eq(0).addClass("active");
    container.find(".page-main").addClass(container.find(".index-slide").eq(0).data("bg"));

    $(document).on('mousemove', function (e) {
        if ($(e.target).hasClass("index-slide__link") || $(e.target).hasClass("index-slide__cursor")) {
            $('.index-slide__cursor').removeClass("hidden");
            $('.index-slide__cursor').css({
                left: e.pageX - $('.index-slide__cursor').innerWidth() / 2,
                top: e.pageY - $('.index-slide__cursor').innerHeight()
            });
        }
        else {
            $('.index-slide__cursor').addClass("hidden");
        }
    });

    window.indexDuring = true;
    setTimeout(() => {
        window.indexDuring = false;
    }, 1000);

    function indexNextSlide(slide) {
        if (!window.indexDuring) {
            window.indexDuring = true;
            slide.addClass("during");
            var activeSlide;
            if (slide.index() + 1 == slideLength) {
                activeSlide = $(".index-slide").eq(0);
            }
            else {
                activeSlide = slide.next(".index-slide")
            }
            activeSlide.addClass("active");
            container.find(".page-main").removeClass().addClass(activeSlide.data("bg") + " page-main");
            setTimeout(() => {
                window.indexDuring = false;
                slide.removeClass("active during");
            }, 1000);
        }
    }

    function indexPrevSlide(slide) {
        if (!window.indexDuring) {
            window.indexDuring = true;
            slide.addClass("during");
            var activeSlide;
            if (slide.index() == 0) {
                activeSlide = $(".index-slide").eq(slideLength - 1);
            }
            else {
                activeSlide = slide.prev(".index-slide");
            }
            activeSlide.addClass("active");
            container.find(".page-main").removeClass().addClass(activeSlide.data("bg") + " page-main");
            setTimeout(() => {
                window.indexDuring = false;
                slide.removeClass("active during");
            }, 1000);
        }
    }

    container.find(".index-slide__arrow.swiper-button-next").on("click", function () {
        indexNextSlide($(this).closest(".index-slide"));
    });
    container.find(".index-slide__arrow.swiper-button-prev").on("click", function () {
        indexPrevSlide($(this).closest(".index-slide"));
    });
    container.find(".index-slide").each((index, item) => {
        if ($(item).closest(".index-slide").index() == 0) {
            window["prevTitle" + index] = $(".index-slide").eq(slideLength - 1).find(".index-slide__title").html();
        }
        else {
            window["prevTitle" + index] = $(item).prev(".index-slide").find(".index-slide__title").html();
        }
        if ($(item).closest(".index-slide").index() + 1 == slideLength) {
            window["nextTitle" + index] = $(".index-slide").eq(0).find(".index-slide__title").html();
        }
        else {
            window["nextTitle" + index] = $(item).next(".index-slide").find(".index-slide__title").html();
        }
        $(item).find(".index-slide__arrow.swiper-button-prev").find(".index-slide__arrow-text").html(window["prevTitle" + index]);
        $(item).find(".index-slide__arrow.swiper-button-next").find(".index-slide__arrow-text").html(window["nextTitle" + index]);
    });

    var counter1 = 0, counter2, counter3, marker = true;

    $('.index-slider__block').bind('mousewheel', function (e) {
        counter1 += 1;
        if (marker) {
            if (e.originalEvent.wheelDelta < 0) {
                wheelStart('next');
            }
            else {
                wheelStart('prev');
            }
        }
        return false;
    });

    function wheelStart(dir) {
        marker = false;
        if (dir == 'next') {
            indexNextSlide($(".index-slide.active"));
        }
        else if (dir == 'prev') {
            indexPrevSlide($(".index-slide.active"));
        }
        wheelAct();
        counter3 = new Date();
    }
    function wheelAct() {
        counter2 = counter1;
        setTimeout(function () {
            if (counter2 == counter1) {
                wheelEnd();
            } else {
                wheelAct();
            }
        }, 50);
    }

    function wheelEnd() {
        marker = true;
        counter1 = 0;
        counter2 = false;
        counter3 = false;
    }

    var el = $(".index-slider__block")[0];
    if (el) {
        swipedetect(el, function (swipedir) {
            if (swipedir == 'up' || swipedir == 'left') {
                indexNextSlide($(".index-slide.active"));
            }
            if (swipedir == 'down' || swipedir == 'right') {
                indexPrevSlide($(".index-slide.active"));
            }
        });
    }
}

function sliders(container) {

    // Brands slider
    (function () {
        var brandsSliderEl = container.find(".js-brands-slider");
        var brandsSlider = new Swiper(brandsSliderEl, {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop: false,
            freeMode: true,
            navigation: {
                nextEl: ".brands-button.swiper-button-next",
                prevEl: ".brands-button.swiper-button-prev",
            },
            breakpoints: {
                1651: {
                    spaceBetween: 40,
                    slidesPerView: 8
                },
                1401: {
                    slidesPerView: 8,
                    spaceBetween: 30
                },
                992: {
                    slidesPerView: 7,
                    loop: true,
                    spaceBetween: 20
                }
            }
        });
    })();

    // Brands side slider
    (function () {
        var brandsSliderSideEl = container.find(".js-brands-slider-side");
        var brandsSliderSide = new Swiper(brandsSliderSideEl, {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop: false,
            freeMode: true,
            breakpoints: {
                1651: {
                    spaceBetween: 30,
                    slidesPerView: 6,
                },
                651: {
                    loop: true,
                    slidesPerView: 5,
                    spaceBetween: 20,
                }
            },
            navigation: {
                nextEl: ".brands-button.swiper-button-next",
                prevEl: ".brands-button.swiper-button-prev",
            },
        });
    })();

    // History slider
    (function () {
        var historySliderEl = container.find(".js-history-slider");
        var historySlider = new Swiper(historySliderEl, {
            slidesPerView: 1,
            // loop: true,
            noSwiping: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: ".swiper-button-next.about-history__slider-button",
                prevEl: ".swiper-button-prev.about-history__slider-button",
            },
            on: {
                init: function () {
                    $(".about-history__block").eq(this.realIndex).addClass("active");
                },
                transitionEnd: function () {
                    $(".about-history__block").removeClass("active");
                    $(".about-history__block").eq(this.realIndex).addClass("active");
                },
            }
        });
    })();


    // Default slider
    (function () {
        var defaultSliderEl = container.find(".js-default-slider");
        defaultSliderEl.each((i, item) => {
            var nextArrow = $(item).next(".default-slider__footer").find(".swiper-button-next"),
                prevArrow = $(item).next(".default-slider__footer").find(".swiper-button-prev");
            window["default-slider" + i] = new Swiper(item, {
                slidesPerView: 1,
                loop: true,
                speed: 500,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                navigation: {
                    nextEl: nextArrow,
                    prevEl: prevArrow,
                },
                on: {
                    slideChange: function () {
                        $(item).next(".default-slider__footer").find(".default-slider__text span").addClass("hidden");
                        setTimeout(() => {
                            $(item).next(".default-slider__footer").find(".default-slider__text span").text($(item).find(".swiper-slide").eq(window["default-slider" + i].activeIndex).find(".default-slider__item").data("text"));
                            $(item).next(".default-slider__footer").find(".default-slider__text span").removeClass("hidden");
                        }, 250);
                        setTimeout(() => {
                            if ($(item).find("iframe").length > 0) {
                                $(item).find("iframe").remove();
                            }
                        }, 500);
                    },
                },
            })
        });
    })();
}